import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as T } from 'react-intl';

import { Cover, FAQs, GridResults, Layout, SectionTextMedia, SEO } from 'components';
import { routes } from 'utils/routes';
import { whiteningFaqsData } from 'utils/config/faqs';
import reviewSelfies from 'utils/config/reviewSelfies';

import content from './teeth-whitening-upgrade.yml';

const TeethWhiteningPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale} className='teeth-whitening-wrapper'>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <Cover
      title='Premium Teeth Whitening'
      text="Whiten your teeth while getting them straight using the world’s most effective teeth whitening system."
      image={{ url: 'pages/whitening_cover.jpg', alt: 'Woman pouring a transparent paste into her teeth mould' }}
      button={{ label: 'Get started', ...routes.getStarted }}
      className='cover-whitening'
    />

    <T id="whitening.usa">{txtUSA =>
      <T id="whitening.std">{txtSTD =>
        <SectionTextMedia
          title='Simply the best'
          text={`Sold by dentists in the USA for ${txtUSA}<br/><br/>We make it available for only ${txtSTD}`}
          image={{ url: 'pages/whitening_one.jpg', alt: 'Before and after comparision of teeth getting whiter' }}
        />
      }</T>
    }</T>

    <SectionTextMedia
      title='Why we use KöR'
      text="It gives impressive and longer lasting results.<br/><br/>It’s safe and only available when prescribed by a dentist.<br/><br/>It requires no extra time or appointment.<br/><br/>It comes with desensitizer to prevent sensitivity issues."
      image={{ url: 'pages/whitening_two.jpg', alt: 'Doctor Aalok explaining the proccess' }}
      videoUrl='https://straight-teeth-direct.wistia.com/medias/h0ssawni0h'
      lightbox
      isVideo
      reverse
    />

    <FAQs faqsData={whiteningFaqsData} title='Teeth Whitening FAQs' />

    <h3 className='section-title mb-50 mt-90'>#MySmileDelivered reviews</h3>
    <GridResults link={{ label: 'View more results' }} pictures={reviewSelfies} trustpilot slide hasAction className='mb-90' />
  </Layout>
);

TeethWhiteningPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default TeethWhiteningPage;
